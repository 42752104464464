/* screen breaks */

/** menu heights **/

.zzmedia_buero-overview { /* screen breaks */ /** menu heights **/ /* screen breaks */ /** menu heights **/ /** Variables and mixins for screen breaks **/
}

.zzmedia_buero-overview #project-teaser {
	display: flex;
	justify-content: center;
	align-items: center;
}

.zzmedia_buero-overview #project-teaser p {
	text-align: center;
	width: 100%;
}

.zzmedia_buero-overview .category-teaser > div > div {
	lost-waffle: 1/2 2 10px;
}

@media only screen and (min-width: 821px) {

.zzmedia_buero-overview .category-teaser:nth-child(2n-1) > div:first-child {
	lost-move: 1/2 row 10px;
}

.zzmedia_buero-overview .category-teaser:nth-child(2n-1) > div:last-child {
	lost-move: -0.5 row 10px;
}

}

